<template>
    <div>
        <v-app-bar flat height="40" color="#fff" style="color:black" app>
          <v-spacer></v-spacer>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" color="black"
                v-on="on" 
                icon text @click="nav = !nav"><i class="fas fa-bars"></i></v-btn>
            </template>
            <span>Desplegar Menu</span>
            </v-tooltip>
        </v-app-bar>



        <v-navigation-drawer v-model="nav" fixed app right>
            <div style="margin:100px auto;display:block;">
            <v-list dense shaped>
                <v-list-item to="/" color="#063155"
                active-class="hash"
                >
                <v-list-item-title style="font-weight:600">Inicio</v-list-item-title>
                </v-list-item>
                <v-list-group color="#2379bf"
                append-icon="fas fa-sort-down"
                    v-for="item in items"
                    :key="item.title"
                    no-action
                    active-class="hash"
                >
                    <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title" style="font-weight:600"></v-list-item-title>
                    </v-list-item-content>
                    </template>

                    <v-list-item
                    v-for="child in item.items"
                    :key="child.title"
                    :to="child.link"
                    >
                    <v-list-item-content>
                        <v-list-item-title v-text="child.title"></v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>

            </div>
        </v-navigation-drawer>
    </div>
</template>

<style>
.hash{
  border-left: solid 5px #237abf;
  color: #063155;
}</style>

<script>
export default {
    data(){
        return {
          selectedItem:1,saludo:'',
            nav:true,
            items: [
        {
          items: [
            { title: 'Crear / Ver', link: '/Clientes' },
          ],
          title: 'Clientes',
        },
        {
          items: [
            { title: 'Crear / Ver', link: '/Inventario' },
          ],
          title: 'Productos',
        }
      ], 
        }
    },
    methods:{
        
    }
}
</script>