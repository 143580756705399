<template>
  <v-app>
    <toolbars></toolbars>
    <v-main style="background: #F4F4F4;">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import toolbars from './components/toolbar.vue'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components:{
    toolbars
  }
};
</script>
