import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../components/details.vue')
  },
  {
    path: '/Clientes',
    name: 'new_cliente',
    component: () => import('../components/Clients.vue')
  },
  {
    path: '/Inventario',
    name: 'inventario',
    component: () => import('../components/Inventario.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
